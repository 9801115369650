<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item"><a href="#/youngdad-2">我女友懷孕了</a></li>
              <li class="breadcrumb-item active" aria-current="page"><a href="#">選擇答案</a></li>
            </ol>
          </nav>
        </section>
        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="text-box">
              <p class="step-text" data-aos="fade-up">ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up" >選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up">為兩人所想，為兩人所選。</p>
            </div>
            <div class="container step-box">
              <div class="select-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜我的社會責任｜</h2>
                </div>
                <div class="step-header">
                  <img data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                    <p data-aos="fade-up">可以想像在得知您女友遭遇到這樣狀況時，或許也會有些自責與愧咎！<br>
                       這想法、感受都是真實也很自然的，只是在決定做什麼前，<br>
                       或許也鼓勵您多參照在選擇各種方式前，先想想這樣方式對雙方的影響及會面臨的處境與感受。<br>
                       以下是屬於您可行使的相關權利，供您作參考與了解。
                    </p>
                  <hr data-aos="fade-up">
                </div>
                <div class="select-menu">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3>1.與不同年齡層對象發生合意性行為之刑責為何？</h3>
                        <p class="mb-0">現今的社會有許多種家庭型態：雙親家庭、隔代教養、單親家庭等等，雖然妳目前仍未成年、妳仍有權利決定是否要留下寶寶親自撫育。但是在選擇自己撫養寶寶是必須面對不同的挑戰，不管是自己的學業可否繼續、經濟可否負擔以及教養等等都是重要課題</p>
                        <ul class="list">
                          <li>(1)對未滿14歲男女為性交者，處3年以上10年以下有期徒刑。</li>
                          <li>(2)對14歲以上未滿16歲之男女為性交者，處7年以下有期徒刑。</li>
                          <li>(3)未滿14歲之男女為猥褻之行為者，處6月以上5年以下有期徒刑。</li>
                          <li>(4)14歲以上未滿16歲之男女為猥褻之行為者，處3年以下有期徒刑。</li>
                        </ul>
                        <p class="mb-60">
                          <span class="fw-bold">加害人未滿十八歲：</span><br>
                        若本罪之加害人為未滿十八歲，則屬告訴乃論之罪。其立法目的在於，像這樣兩情相悅的情況，雙方又都未成年，根本不應用刑事制裁的方式來解決，應讓被害人自行斟酌其具體情況，決定是否要對加害人提出告訴。
                        </p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>2.何謂兩小無猜條款？</h3>
                        <p>未滿十八歲之人對未滿十六歲之男女性交者，可以減輕或免除其刑，而且屬於告訴乃論罪，也就是必須要向法院提告才審理，若無提告，法院、警局等相關機關得知此情況也不會偵辦。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>3.通報性侵害防治中心有什麼好處？</h3>
                        <p>通報除了能讓主管機關依循性侵害防治與處遇之流程，發動警政、社政等相關單位進行協助外，更能有效針對當事人的問題與需求加以評估後，協助其取得、運用所需之社會資源，並減少其誤以為僅能獨自承擔，而在困惑、無助與徬徨下，作出令自己遺憾的決定。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>4.通報性侵害防治中心後，會有哪些流程？</h3>
                        <p>未滿16歲之人即使是自願發生性關係，也需要進行一般性侵害案件處遇流程。社工將通報的資料送至家庭暴力暨性侵害防治中心後，社工將會盡快與案主作初步聯繫，評估是否需要提供緊急處遇，如聲請保護令、緊急庇護安置等，並依案主需求後續提供經濟、就學、醫療等服務。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>5.女友懷孕了，我是否會被提告？</h3>
                        <p>若女方未滿16歲，男方將以公訴罪起訴；倘若女方為16歲以上未滿18歲，男方則屬告訴乃論罪；又如女方為成年人，則需要進一步尋求專業法律資源，了解提告之可能性與用何種罪名提告等相關資訊。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>6.女方若選擇人工流產，法律是否有規定男方必須負擔賠償或醫療費用？</h3>
                        <p>法律並無規定男方必須支付女方人工流產費用，但若女方為未成年人，則可透過提告讓男方對其行為負擔刑責。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>7.新生兒在多久期限內要完成報戶口？若未依規定辦理會被罰款嗎？</h3>
                        <p>出生登記之申請，應於新生兒出生後三十日內為之，如申請逾期者，戶政事務所會以書面催告應為申請之人，並處新台幣三百元以下罰鍰；出生登記，經催告仍不申請者，戶政事務所得逕為登記（由戶政事務所主任代立姓名），並處新台幣六百元以下罰鍰。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>8.未成年小媽媽所生下的Baby其監護權屬於誰？</h3>
                        <p>寶寶監護權為小媽媽所有，但經生父認領則可隨生父姓，並與生母共同擁有監護權，倘若生父未滿18歲，則要生父的父母同意。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>9.在未成年的情形下，如何將孩子的戶口登記在男方的戶籍？</h3>
                        <p>未婚生子報出生登記後從母姓，經生父認領後視同婚生子女，可從父姓或母姓，戶口可報在生父或生母戶內，出生登記以父、母、祖父、祖母、戶長或撫養人為申請人，需備妥出生證明書、戶口名簿、申請人身分證及印章，親至戶政事務辦理。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>10.產後家人不願意讓寶寶報戶口，該如何處理？</h3>
                        <p>應先了解家人反對之理由，討論其可接受之解決辦法，然寶寶出生後一段時間內，如無報戶口，基本上戶政單位會直接報戶口，僅是差在寶寶出生後一個月到報戶口這段空窗期，恐有會有健保的問題。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>11.認領或強制認領的權利是什麼？</h3>
                        <p class="mb-0">若男方與女方無婚姻關係，所生下寶寶為非婚生子女，男方與寶寶僅有事實上的血緣關係，而沒有法律上的親子關係。按民法第1065 條：「非婚生子女經生父認領者，視為婚生子女，其經生父撫育者，視為認領。認領登記應備證件為戶口名簿、申請人國民身分證、印章或簽名、生父認領同意書或相關證明文件至戶政機關辦理。倘若生父不願負撫養責任，可按照強制認領之規定，非婚生子女、或其生母、或其他法定代理人，於符合下列之情形時，得按民法第1067條強制請求生父認領之：</p>
                        <ul class="list">
                          <li>(1)受胎期間生父與生母有同居之事實者。</li>
                          <li>(2)由生父所作之文書可證明其為生父者。</li>
                          <li>(3)生母為生父強姦或略誘成姦者。</li>
                          <li>(4)生母因生父濫用權勢成姦者。</li>
                        </ul>
                        <p>強制認領的請求年限：非婚生子女本人自成年後兩年內（指20-22歲間），若不行使而消滅。生母及其他法定代理人自子女出生之後七年間，若不行使而消滅。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>12.孩子生下來之後由男方帶回照顧，並由男方報戶口，跟男方同姓，但男方也以此理由，不讓母親跟孩子見面，有什麼方式可將孩子帶回？</h3>
                        <p>在未婚懷孕狀態下，監護權應為母親所有，故建議可至戶政事務所查詢孩子之戶口登記及相關資料，以檢視男方是否有照正常程序或偽造文書之可能，亦可尋求相關法律諮詢了解資訊</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>13.非婚生子之合法性及出生證明之生父欄如何顯示？</h3>
                        <p>非婚生子只要依法為寶寶報戶口，並非違法之行為。寶寶出生證明之生父欄多以空白呈現，但若辦理生父認領，則可顯示生父姓名。建議可親洽各戶政事務所了解之。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>14.未成年人欲訂婚、結婚之相關規定為何？</h3>
                        <p>民法暫定112年下修18歲為成年，同步修訂男女皆須滿17歲才能訂婚、18歲才能結婚。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>15.家人間的侵害？</h3>
                        <p>對於因親屬、監護、教養、教育、訓練、救濟、醫療、公務、業務或其他相類關係受自己監督、扶助、照護之人利用權勢或機會為性交者，處6月以上5年以下有期徒刑；因前項情形而為猥褻之行為者，處3年以下有期徒刑。</p>
                      </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Article></Article>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
import Article from '../../components/Article.vue'

export default {
  data () {
    return {
    }
  },
  mounted () {

  },
  components: {
    Consult,
    Article
  }
}
</script>
